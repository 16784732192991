<template>
  <v-hover v-slot:default="{ hover }">
    <v-card
      v-if="data.type !== 'external_link' && data.type !== 'internal_page_link'"
      :class="`elevation-${hover ? 12 : 2}`"
      class="mx-auto transition-swing"
            :min-height="cardHeight"

      @click="$emit('click')"
    >
  
      <v-img
        :src="thumbnail"
        class="fill-space"
      >
        <v-icon color="white" class="pa-2 text--shadow">{{icon}}</v-icon>
      </v-img>
      <v-card-title class="pa-4"><div class="header-class subtitle-2">{{ title }}</div></v-card-title>
      <v-card-text v-if="caption !== ''">{{ caption }}</v-card-text>
      <v-card-text v-if="accessibility_text !== ''">{{accessibility_text}}</v-card-text>

    </v-card>
    <v-card
      v-else-if="data.type == 'external_link' || data.type == 'internal_page_link' "
      :class="`elevation-${hover ? 12 : 2}`"
      id="test-cards"
      :min-height="cardHeight"
      class="mx-auto transition-swing"
      :href="url"
    >
      <v-img
        :src="thumbnail"
        class="fill-space"
      >
        <v-icon color="white" class="pa-2 text--shadow">{{icon}}</v-icon>
      </v-img>
      <v-card-title class="pa-4"><div class="header-class subtitle-2">{{ title }}</div></v-card-title>
      <v-card-text v-if="caption !== ''">{{ caption }}</v-card-text>
      <v-card-text v-if="accessibility_text !== ''">{{accessibility_text}}</v-card-text>
    </v-card>
  </v-hover>
</template>

<script>
export default {
  name: "CardGalleryBlockCard",
  props: ["data", "cardHeight"],
  data: () => ({
    thumbnail: null
  }),
  mounted(){

    // Thumbnail
    if (this.data.type === 'page_struct') {
      this.$store.dispatch('wagtail/getPageById', this.data.value.page.id).then((data) => {
        this.thumbnail = data.thumbnail[0].value.file
      })
    } else if (this.data.value.thumbnail || this.data.type == 'beforeafter') {
      if (this.data.type == "beforeafter") {
        this.thumbnail = this.data.value.after.file;
      }
      else if (this.data.value.thumbnail.thumbnail && !this.data.value.thumbnail.full_image) {
        this.thumbnail = this.data.value.thumbnail.thumbnail
      }
      else if (this.data.value.thumbnail.thumbnail && this.data.value.thumbnail.full_image) {
        this.thumbnail = this.data.value.thumbnail.file
      } else {
        this.thumbnail = this.data.value.thumbnail
      }
    } else  {
      this.thumbnail = "/pdf-image.png";
    }

  },

  computed: {
    url() {
      if (this.data.type === "external_link") {
        return this.data.value.url;
      } else if (this.data.type === "internal_page_link") {
        let path = new URL(this.data.value.page.meta.html_url).pathname
        return path;
      }
    },

    icon() {
      if (this.data.type === 'document')
        return 'mdi-file-document'
      if (this.data.type === 'image')
        return 'mdi-image'
      if (this.data.type === 'video')
        return 'mdi-youtube'
      if (this.data.type === 'beforeafter')
        return 'mdi-compare'
      return ''
    },
    title() {
      if (this.data.value.title)
        return this.data.value.title
      if (this.data.value.page.title)
        return this.data.value.page.title
      return ""
    },
    caption() {
      if (this.data.value.caption)
        return this.data.value.caption
      return ""
    },
    accessibility_text() {
      if (this.data.value.accessibility_text)
        return this.data.value.accessibility_text
      return ""
    }

  },
  methods:{
  }
};
</script>

<style scoped>
.document-bg {
  background-color:lightgrey;
}

.text--shadow {
  text-shadow: 1px 2px 5px black;
  opacity: 0.75;
}

.header-class{
    white-space: nowrap ;
    word-break: normal;
    overflow: hidden ;
    text-overflow: ellipsis;
}
 

</style>